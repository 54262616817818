import axios from './axios';

/*
 * GET METHODS
 */

// GET SEARCH RESULT
export const getSearchResult = (searchInput, mode) => {
  // console.log('mode : ', mode);
  const URL = mode.length ? `/common/search?mode=${mode}&text=${searchInput}` : `/common/search?text=${searchInput}`;

  return () =>
    axios
      .get(URL)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};
