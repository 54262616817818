import {
  LOGOUT,
  GET_OTP_SUCCESS,
  GET_OTP_FAIL,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  UPDATEUSER,
  SIGNIN,
  RESET_OPT_VALID_STATUS,
} from './authTypes';

export const getOptSuccess = (data) => ({
  type: GET_OTP_SUCCESS,
  payload: data,
});
export const getOptFailure = () => ({ type: GET_OTP_FAIL });

export const sendOptSuccess = (data) => ({
  type: SEND_OTP_SUCCESS,
  payload: data,
});
export const sendOptFailure = () => ({ type: SEND_OTP_FAIL });

export const resetOtpValidSuccess = () => (dispatch) => dispatch({ type: RESET_OPT_VALID_STATUS });

export const updateUser = (user) => ({ type: UPDATEUSER, payload: user });

export const logout = () => ({ type: LOGOUT });

export const singIn = (user) => ({ type: SIGNIN, payload: user });

