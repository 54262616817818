import { getOptFailure, getOptSuccess, sendOptFailure, sendOptSuccess, singIn } from '../redux/auth/authActions';
import axios from './axios';

export const GetUserOTP = (number) => (dispatch) =>
  axios
    .post(`user/login?phone=${number}`, { phone: number })
    .then((response) => {
      const data = response.data.payload;
      dispatch(getOptSuccess(data));
    })
    .catch(() => {
      dispatch(getOptFailure());
    });

export const SendUserOTP = (userId, otp, cb) => (dispatch) =>
  axios
    .get(`user/${userId}/verify-otp?otp=${otp}`)
    .then((response) => {
      const data = response.data.payload;
      const token = response.headers['x-auth'];
      localStorage.setItem('authToken', token);
      localStorage.setItem('polBolUser', JSON.stringify(data));
      dispatch(sendOptSuccess({ user: data, token }));
      if (cb) cb();
    })
    .catch(() => {
      dispatch(sendOptFailure());
    });

export const SINGIN = () => (dispatch) => dispatch(singIn());
