/* eslint-disable react/jsx-props-no-spreading */
import useStyles from './buttonCompStyles';

function Button(props) {
  const classes = useStyles();
  const { text, onClick, variant, children } = props;
  // console.log({ ...props })
  return (
    <button {...props} className={variant === 'secondary' ? classes.btnSecondary : classes.btn} onClick={onClick}>
      <span>{text}</span>
      {children}
    </button>
  );
}

export default Button;
