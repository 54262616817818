import {
  LOGOUT,
  GET_OTP_SUCCESS,
  GET_OTP_FAIL,
  SEND_OTP_SUCCESS,
  SEND_OTP_FAIL,
  UPDATEUSER,
  SIGNIN,
  RESET_OPT_VALID_STATUS,
} from './authTypes';

const token = localStorage.getItem('authToken');
const user = JSON.parse(localStorage.getItem('polBolUser'));

const initialState = {
  token: token || null,
  isAuthenticated: !!token,
  isOTPsend: false,
  user: user || null,
  isOTPValid: true,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGNIN:
      localStorage.setItem('authToken', JSON.stringify(action.payload.token));
      localStorage.setItem('polBolUser', JSON.stringify(action.payload.user));
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
        isOTPsend: false,
      };
    case GET_OTP_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        isOTPsend: true,
        user: action.payload,
      };

    case SEND_OTP_SUCCESS:
      return {
        ...state,
        isOTPValid: true,
        token: action.payload.token,
        isAuthenticated: true,
        isOTPsend: false,
        user: action.payload.user,
      };

    case RESET_OPT_VALID_STATUS:
      return {
        ...state,
        isOTPValid: true,
      };

    case GET_OTP_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isOTPsend: false,
      };

    case SEND_OTP_FAIL:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isOTPValid: false,
      };

    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isOTPsend: false,
        user: null,
      };

    case UPDATEUSER: {
      // console.log('reached...');
      return { ...state, user: action.payload };
    }

    default:
      return state;
  }
};

export default authReducer;

