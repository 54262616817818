import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  headingWrapper: {
    // marginTop: '30px',
    marginBottom: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },

  headingText: {
    // fontSize: '30px',
    background: theme.palette.primary.mainGradient,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 18,
    lineHeight: '127%',
    /* or 23px */

    textAlign: 'center',
  },
}));
