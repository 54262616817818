/* eslint-disable no-param-reassign */
import axios from 'axios';
import { OPEN_SNACKBAR } from '../redux/snackbar/snackbarTypes';
import store from '../redux/store';

const axiosInstance = axios.create({
  baseURL: 'https://backend.polbol.in/backend',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.config && error.config.url.includes('verify-otp?otp')) return Promise.reject(error);
    store.dispatch({
      type: OPEN_SNACKBAR,
      payload: {
        type: 'error',
        message: 'Something went wrong please try again.',
      },
    });
    return Promise.reject(error);
  },
);

export default axiosInstance;

