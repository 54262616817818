import { Suspense } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { closeSnackBar } from 'redux/snackbar/snackbarAction';

// Components
import CSSBaseline from '@mui/material/CssBaseline';
import Spinner from 'Components/Spinner/Spinner';
import SnackBar from 'Components/Snackbar/SnackBar';
import { ThemeProvider } from '@material-ui/core';
import Navbar from './Components/Navbar/NavBarFinal';

// Theme
import Routes from './Routes';
import Footer from './Components/Footer/FooterFinal';
import theme from './theme';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-modal-video/scss/modal-video.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './index.css';

const App = function App() {
  const { open, message } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <CSSBaseline>
        <Router>
          <SnackBar
            open={open}
            setOpen={() => dispatch(closeSnackBar())}
            snack={message}
            style={{ height: '100%' }}
            onClose={() => dispatch(closeSnackBar())}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
          <Navbar />
          <div className="main-view-container">
            <Suspense fallback={<Spinner />}>
              <Routes />
            </Suspense>
          </div>
          <Footer />
        </Router>
      </CSSBaseline>
    </ThemeProvider>
  );
};

export default App;

