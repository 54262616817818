import { SET_POLLS_ACTIVE, SET_POLLS_EXPIRED } from './pollTypes';

const initialState = {
  active: [],
  expired: [],
};

const pollReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POLLS_ACTIVE:
      return { ...state, active: action.payload };

    case SET_POLLS_EXPIRED:
      return { ...state, expired: action.payload };
    default:
      return state;
  }
};
export default pollReducer;
