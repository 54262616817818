import { useMediaQuery } from 'react-responsive';

import QuizResultCircle from 'Components/QuizResultCircle/QuizResultCircle';
import DiscoverQuizs from './DiscoverQuizs';

// import HeadingComp from 'Components/HeadingComp/HeadingComp'
// import './stylesheet.css'

import styles from './Quiz.module.css';

function QuizResult({ correct, questions, handleCancel, quizData }) {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <div className={styles.ResultContainer}>
      {!isMobile && <div className={styles.pinkSmallBG} />}
      {/* <HeadingComp
        heading={'Results'}
        isQuiz={true}
        style={{ color: '#000', WebkitTextFillColor: 'unset' }}
      /> */}
      <h2 style={{ fontWeight: '600' }}>Result</h2>
      <div className={styles.StatusContainer} style={{ width: isMobile && '90%' }}>
        <QuizResultCircle correct={correct} total={questions} />

        <h4 className={styles.ResultsScore}>
          Your score is <span className={styles.BottomTitleRes}>{quizData?.score}</span>
        </h4>
        <div className={styles.BottomWrapper} style={{ width: isMobile && '100%' }}>
          <div className={styles.BottomBox} style={{ width: isMobile && '48%', height: isMobile && '100px' }}>
            <div
              style={{
                minHeight: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className={styles.BottomBoxTitle}>No of attempts</span>
            </div>
            <div className={styles.BottomStats}>{quizData?.attempts}</div>
          </div>
          <div className={styles.BottomBox} style={{ width: isMobile && '48%', height: isMobile && '100px' }}>
            <div
              style={{
                minHeight: 40,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className={styles.BottomBoxTitle}>Accuracy of Correct Answers</span>
            </div>
            <div className={styles.BottomStats}>{quizData?.accuracy}%</div>
          </div>
        </div>
      </div>
      <div className={styles.ExitTryWrapper} style={{ width: isMobile && '70%' }}>
        <div role="presentation" className={styles.ExitTryBox} onClick={handleCancel}>
          Exit
        </div>
        <div role="presentation" className={styles.ExitTryBox} onClick={handleCancel}>
          Try Again
        </div>
      </div>

      <DiscoverQuizs />
    </div>
  );
}

export default QuizResult;
