/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { Link } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './styles.css';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,

        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

export default function CustomizedMenus({ img, profile, logout }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      >
        <img
          src={img}
          style={{
            width: 30,
            height: 30,
            objectFit: 'cover',
            borderRadius: '50%',
          }}
          alt=""
        />
        <KeyboardArrowDownIcon />
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        style={{ paddingBottom: 20 }}
        onClose={handleClose}
      >
        <div style={{ paddingBottom: 10 }}>
          <div onClick={handleClose} style={{ padding: 10 }} className="firstDiv">
            <Link to={profile} style={{ textDecoration: 'none' }} className="profileLink">
              <img
                src={img}
                style={{
                  width: 30,
                  height: 30,
                  objectFit: 'cover',
                  borderRadius: '50%',
                }}
                alt=""
              />

              <span style={{}} className="ProfileText">
                Profile
              </span>
            </Link>
          </div>
          <div
            onClick={() => {
              handleClose();
              logout();
            }}
            className="LinearDiv"
            style={{ cursor: 'pointer' }}
          >
            Logout
          </div>
        </div>
      </StyledMenu>
    </div>
  );
}

