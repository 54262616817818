import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  MuiButton: {
    styleOverrides: {
      // Name of the slot
      root: {
        // Some CSS
        background: '#000',
        fontSize: '10rem',
      },
    },
  },
  palette: {
    primary: {
      main: 'rgba(108, 0, 193, 0.83)',
      mainGradient: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
    },
    secondary: {
      main: '#E5E5E5',
      violet: 'rgba(108, 0, 193, 0.83)',
      purple: '#6F57FF',
      blue: '#0951CE',
      skyBlue: '#5B97FF',
      grey: '#878787',
      lightGrey: '#F4F1F1',
    },
    white: {
      main: '#FFFFFF',
      dull: 'rgba(255, 255, 255, 0.8)',
    },
    black: {
      main: '#212121',
      dark: '#000000',
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontWeight: 700,
      fontSize: '27.3008px',
      lineHeight: '110.5%',
    },
    h2: {
      fontWeight: 700,
      fontSize: '19.1935px',
      lineHeight: '115.5%',
    },
    h3: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '115.5%',
    },
    body1: {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '18px',
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: '10px',
      lineHeight: '115.5%',
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: '9px',
      lineHeight: '115.5%',
    },
    caption: {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '115.5%',
    },
    overline: {
      fontWeight: '300',
      marginTop: '4px',
      fontSize: '8.97318px',
      lineHeight: '110.5%',
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 250,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  button: {},
});

export default theme;
