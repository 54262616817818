import axios from './axios';

/*
 *  GET METHODS
 */

// GET QUIZ
export const getQuiz = (params = {}, secure = false) => {
  const URL = secure ? '/quiz/fetchQuiz' : '/quiz/fetchQuiz/guest';
  return () =>
    axios
      .get(URL, { params })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// GET START QUIZ
export const getStartQuiz = (params = {}, secure = false) => {
  const URL = secure ? '/quiz/start' : '/quiz/start/guest';

  return () =>
    axios
      .get(URL, { params })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// GET submit answer
export const getSubmitAnswer = (params = {}, secure = false) => {
  const URL = secure ? '/quiz/submitAnswer' : '/quiz/submitAnswer/guest';

  return () =>
    axios
      .get(URL, { params })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// GET END QUIZ
export const getEndAnswer = (params = {}, secure = false) => {
  const URL = secure ? '/quiz/end' : '/quiz/end/guest';

  return () =>
    axios
      .get(URL, { params })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// GET END QUIZ
export const getTimeoutQuiz = (params = {}, secure = false) => {
  const URL = secure ? '/quiz/timedOut' : '/quiz/timedOut/guest';

  return () =>
    axios
      .get(URL, { params })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// GET HINT QUIZ
export const getHintQuiz =
  (params = {}) =>
  () =>
    axios
      .get('/quiz/hint', { params })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));

// GET ALL CATEGORIES
export const getAllCategories = () => () =>
  axios
    .get('/quiz/fetchAllCategories')
    .then((response) => response.data)
    .catch((error) => Promise.reject(error && error.response && error.response.data));

// GET THE RULES BOOK
export const getRuleBook = () => () =>
  axios
    .get('/quiz/ruleBook')
    .then((response) => response.data)
    .catch((error) => Promise.reject(error && error.response && error.response.data));
