import {
  SET_CATEGORY_STATE,
  SET_DESC_STATE,
  SET_EXPECTEDSIGN_STATE,
  SET_LIFESPAN_STATE,
  SET_PHOTO_STATE,
  SET_PROBLEM_STATE,
  SET_REFLINK_STATE,
  SET_TITLE_STATE,
  RESET_STATE,
} from './petitionTypes';

const initialState = {
  category: '',
  title: '',
  problem: null,
  reflink: '',
  photo: null,
  description: '',
  expectedSignatures: 0,
  lifespan: '',
};

const petitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_STATE:
      return { ...state, category: action.payload };

    case SET_TITLE_STATE:
      return { ...state, title: action.payload };

    case SET_PROBLEM_STATE:
      return { ...state, problem: action.payload };

    case SET_REFLINK_STATE:
      return { ...state, reflink: action.payload };

    case SET_PHOTO_STATE:
      return { ...state, photo: action.payload };

    case SET_DESC_STATE:
      return { ...state, desc: action.payload };

    case SET_EXPECTEDSIGN_STATE:
      return { ...state, expectedSignatures: action.payload };

    case SET_LIFESPAN_STATE:
      return { ...state, lifespan: action.payload };

    case RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
export default petitionReducer;
