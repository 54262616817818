import { combineReducers } from 'redux';

import auth from './auth/authReducer';
import lang from './language/languageReducer';
import pet from './petition/petitionReducer';
import media from './media/mediaReducer';
import search from './search/searchReducer';
import poll from './poll/pollReducer';
import snackbar from './snackbar/snackbarReducer';

const rootReducer = combineReducers({
  auth,
  pet,
  lang,
  media,
  search,
  poll,
  snackbar,
});
export default rootReducer;
