import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  footer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    background: theme.palette.secondary.lightGrey,
    padding: '22px 20px 27px 20px',
    width: '100%',
  },

  linksWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  brandLogo: {
    width: 150,
    height: 50,
  },
  socialLinksWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },

  textWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  citationWrapper: {
    width: '48%',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    textAlign: 'left',
  },

  copyright: {
    color: theme.palette.black.dark,
  },

  creatorLink: {
    fontWeight: 500,
    color: theme.palette.black.dark,
    '& a': {
      color: theme.palette.secondary.blue,
    },
  },

  otherLinksWrapper: {
    display: 'flex',
    width: '48%',
    flexDirection: 'column',
    gap: '5px',
    textAlign: 'right',
  },
  otherLink: {
    '& a': {
      fontWeight: 600,
      color: theme.palette.secondary.blue,
    },
  },
}));
