/* eslint-disable no-constant-condition */
import { useEffect, useState, createRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Grid, Modal as Overlay } from '@mui/material';
//  ------------------- navbar icons ---------
// -------------------
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
// import { DownOutlined } from '@ant-design/icons'
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { logout } from 'redux/auth/authActions';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import './navbar.css';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateLanguage } from 'redux/language/languageActions';
import { setSearchActive, setMode } from 'redux/search/searchActions';

import Search from 'Views/Search/Search';
import CustomizedMenus from 'Components/LogoutComp/CustomizedMenus';
import { ReactComponent as LoginIcon } from '../../assets/icons@/icon-01.svg';
import KWallet from '../../assets/k-wallet.svg';
import UserAvatar from '../../assets/user-avatar.svg';
import searchCancelIcon from '../../assets/search-cancel-icon.svg';
import navbarSearchIcon from '../../assets/navbar-search-icon.svg';
import navbarDrawerIcon from '../../assets/navbar-drawer-icon.svg';
import { ReactComponent as PetitionIcon } from '../../assets/icons@/icon-09.svg';
import { ReactComponent as NewsIcon } from '../../assets/icons@/icon-08.svg';
import { ReactComponent as WalletIcon } from '../../assets/icons@/icon-07.svg';
import { ReactComponent as TVIcon } from '../../assets/icons@/icon-06.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons@/icon-02.svg';
import { ReactComponent as AwardsIcon } from '../../assets/icons@/icon-05.svg';
import { ReactComponent as QuizIcon } from '../../assets/icons@/icon-04.svg';
import { ReactComponent as PollsIcon } from '../../assets/icons@/icon-10.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons@/icon-03.svg';
import polBolLogo from '../../assets/PolbolLogo.svg';

import styles from './Navbar.module.css';
import LoginModal from '../Modal/LoginModal';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(() => ({}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function NavBar({ auth, fetchToken, logout, isSearchActive, isMobile, setSearchActive, setMode }) {
  const [openLogin, setOpenLogin] = useState(false);
  const [visi, setVisi] = useState(false);
  const [tabName] = useState('Home');
  const [openOverlay, setOpenOverlay] = useState(false);
  // const [openOverlayModal, setOpenOverlayModal] = useState(false)
  const [searchInput, setSearchInput] = useState('');
  const HomeRef = createRef();
  const theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const LogoReferrence = {
    Login: <LoginIcon className="navbar_logo" />,
    Home: <HomeIcon className="navbar_logo" />,
    Polls: <PollsIcon className="navbar_logo" />,
    Quiz: <QuizIcon className="navbar_logo" />,
    Awards: <AwardsIcon className="navbar_logo" />,
    'Live TV': <TVIcon className="navbar_logo" />,
    'IKC Wallet': <WalletIcon className="navbar_logo" />,
    News: <NewsIcon className="navbar_logo" />,
    Petitions: <PetitionIcon className="navbar_logo" />,
    Logout: <LogoutIcon className="navbar_logo" style={{ color: 'red', fill: 'red' }} />,
  };

  // const menu = auth.user && (
  //   <Menu className={styles.logoutModal}>
  //     <Menu.Item key="0" style={{ marginBottom: 10 }}>
  //       <Link
  //         className={`${styles.dropItem} ${styles.LinearText}`}
  //         to={`/user/${auth.user._id}`}
  //         style={{ fontSize: '14px', marginTop: '4px' }}
  //       >
  //         <img src={auth.user.avatar} className="avatar" alt="avatar" />{' '}
  //         <span className={styles.BorderLinear}> Profile</span>
  //       </Link>
  //     </Menu.Item>
  //     <Menu.Item key="1">
  //       <span
  //         onClick={() => {
  //           logout();
  //           history.push('/');
  //           localStorage.removeItem('polBolUser');
  //           localStorage.removeItem('authToken');
  //         }}
  //         className={`${styles.auth} ${styles.dropItem} ${styles.LinearBG}`}
  //         style={{ fontSize: '14px' }}
  //       >
  //         <i className="fas fa-sign-out-alt" />
  //         Logout
  //       </span>
  //     </Menu.Item>
  //   </Menu>
  // );
  const handleDrawerOpen = () => {
    // console.log("opening drawer")
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  function setCurrentPage(page) {
    setOpen(false);
    page = page.toLowerCase();
    if (page === 'home') {
      history.push('/');
    } else if (page === 'poll' || page === 'polls') {
      history.push('/polls');
    } else if (page === 'petition' || page === 'petitions') {
      history.push('/petitions');
    } else if (page === 'award' || page === 'awards') {
      history.push('/awards');
    } else if (page === 'quiz') {
      history.push('/quiz');
    } else if (page === 'tv' || page === 'live tv') {
      history.push('/livetv');
    } else if (page === 'news') {
      history.push('/news');
    }
    if (page === 'wallet' || page === 'ikc wallet') {
      if (auth.user) {
        history.push('/user/wallet');
      } else {
        setOpenLogin(true);
      }
    }
  }
  useEffect(() => {
    // url chnaged
    setOpenOverlay(false);
  }, [location]);
  // useEffect(() => {
  // use the location to load the tab name in the nav bar
  // calculating the tab name
  // var path = url.pathname.split('/')[1]
  // var path2 = url.pathname.split('/')[2]
  // if (path.length === 0) {
  //   path = 'PolBol'
  // }
  // if (path === 'user' && path2 === 'wallet') path = 'Wallet'
  // if (path === 'livetv') path = 'livetv'
  // if (path === 'awards' && slug) path = slug
  // console.log(path,slug)
  // if (path === 'awards' && path2 === 'categories') {
  //   path = `IFTA`
  // }
  // console.log(path, path2)
  // setTabName(path)
  // })

  // Search Related Code
  const navbarSearchHandler = () => {
    setSearchActive(!isSearchActive);
    setMode(
      tabName.toLowerCase() !== 'news' && tabName.toLowerCase() !== 'polls' && tabName.toLowerCase() !== 'petitions'
        ? ''
        : tabName.toLowerCase(),
    );
  };
  const isHome = location.pathname.split('/')[1] === '';
  const isNews = location.pathname.split('/')[1] === 'news';
  const isPoll = location.pathname.split('/')[1] === 'polls' || location.pathname.split('/')[1] === 'poll';
  const isPetition =
    location.pathname.split('/')[1] === 'petitions' ||
    location.pathname.split('/')[1] === 'petition' ||
    location.pathname.split('/')[1] === 'petition1' ||
    location.pathname.split('/')[1] === 'petition2' ||
    location.pathname.split('/')[1] === 'petition3' ||
    location.pathname.split('/')[1].split('-')[0] === 'petition';
  const isAwards = location.pathname.split('/')[1] === 'award' || location.pathname.split('/')[1] === 'awards';
  const isQuiz = location.pathname.split('/')[1] === 'quiz' || location.pathname.split('/')[1] === 'quizresult';
  const isLiveTv = location.pathname.split('/')[1] === 'livetv';

  useEffect(() => {
    setSearchActive(false);
    setMode('');
  }, [tabName]);

  return (
    <Box
      sx={
        isMobile
          ? {
              display: 'flex',
              margin: '0px',
              padding: '0px',
            }
          : {
              display: 'flex',
              margin: '0px',
              padding: '0px',
              position: 'sticky',
              top: '0',
              zIndex: '100',
            }
      }
      elevation={0}
    >
      <Link to="/" ref={HomeRef} />
      <CssBaseline />
      <LoginModal
        isMobile={isMobile}
        fetchToken={(token, user) => fetchToken(token, user)}
        show={openLogin}
        onHide={() => {
          setOpenLogin(false);
          // set timeout used to remove overflow hidden injected by some unknow entity (most possibly bootstrap modal)
          // TODO: Remove bootstrap modal and implement MUI Modal
          setTimeout(() => {
            document.body.style.overflow = 'auto';
          }, 500);
        }}
      />
      <AppBar
        elevation={0}
        position="absolute"
        open={open}
        style={{
          margin: '0px',
          padding: '0px',
          position: 'sticky',
          top: 0,
          zIndex: '60',
        }}
        className="height900"
      >
        <Toolbar className="height900" style={{ backgroundColor: 'white', margin: '0px', padding: '0px' }}>
          <Grid container item xs={12}>
            <Grid
              container
              item
              xs={12}
              style={{ margin: '0px 10px', height: 80 }}
              justifyContent="center"
              alignContent="center"
              alignItems="center"
            >
              <Grid
                className="showatt900"
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignContent: 'center',
                  color: 'black',
                }}
              >
                {/* ----tabname -- */}
                <Grid
                  item
                  justifySelf="center"
                  justifyContent="center"
                  justifyItems="center"
                  style={{
                    display: 'flex',
                    marginTop: '10px',
                  }}
                >
                  <img
                    role="presentation"
                    src={polBolLogo}
                    alt="PolBol"
                    style={{
                      cursor: 'pointer',
                      maxHeight: '50px',
                      maxWidth: '150px',
                      marginLeft: '10px',
                      height: '50px',
                      width: '150px',
                    }}
                    onClick={() => HomeRef.current.click()}
                  />
                </Grid>

                {/* open drawer */}
                <Grid
                  item
                  alignContent="center"
                  style={{
                    flex: '0.2',
                    display: 'flex',
                    margin: '10px 5px 0 10px',
                  }}
                >
                  {tabName === 'PolBol' && (
                    <IconButton aria-label="search" onClick={navbarSearchHandler} edge="start">
                      <img
                        src={isSearchActive ? searchCancelIcon : navbarSearchIcon}
                        alt="Search"
                        style={{
                          marginRight: '20px',
                        }}
                      />
                    </IconButton>
                  )}
                  {(isHome || isNews) && (
                    <button
                      style={{
                        border: '0',
                        background: 'inherit',
                        marginLeft: '10px',
                      }}
                      onClick={() => {
                        setOpenOverlay(!visi);
                        setVisi(!visi);
                      }}
                    >
                      <SearchIcon style={{ color: 'black', background: 'none' }} />
                    </button>
                  )}
                  <IconButton aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
                    <img
                      style={{
                        marginLeft: tabName === 'PolBol' ? '0' : '30px',
                      }}
                      src={navbarDrawerIcon}
                      alt="Open menu"
                    />
                  </IconButton>
                </Grid>

                {/* {!isMobile?  */}
                {/* {!isMobile ? (
                  <div
                    style={{
                      color: '#1fa1d2',
                      cursor: 'pointer',
                      marginTop: '20px',
                      flex: '0.2',
                      justifyContent: 'right',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => setCurrentPage('Wallet')}
                  >
                    <div>
                      <img
                        src={KBlueIcon}
                        width="32"
                        height="32"
                        style={{ paddingBottom: '-5px' }}
                      />
                      <i
                        className="fas fa-wallet"
                        style={{
                          marginBottom: '4px',
                          marginTop: '-10px',
                          fontSize: '12px',
                        }}
                      />
                    </div>
                  </div>
                ) : null} */}
              </Grid>
              {/* navbar laptop view ............. */}
              <Grid
                container
                item
                md={12}
                alignContent="center"
                alignItems="center"
                justifyContent="center"
                className="hideat900"
                style={{
                  display: 'flex',
                  maxWidth: '93%',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className={styles.navLogo}>
                  <Link to="/">
                    <img src={polBolLogo} style={{ height: '40px', width: '100%' }} alt="PolBol" />
                  </Link>
                </div>

                <div className={styles.navLinksContainer}>
                  <span className="navText1" onClick={() => setCurrentPage('Home')}>
                    <span
                      style={
                        isHome
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      Home
                    </span>
                  </span>
                  <span className="navText1" onClick={() => setCurrentPage('Poll')}>
                    <span
                      style={
                        isPoll
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      Poll
                    </span>
                  </span>
                  <span className="navText1" onClick={() => setCurrentPage('Petition')}>
                    <span
                      style={
                        isPetition
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      Petition
                    </span>
                  </span>
                  <span className="navText1" onClick={() => setCurrentPage('Award')}>
                    <span
                      style={
                        isAwards
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      Awards
                    </span>
                  </span>
                  <span className="navText1" onClick={() => setCurrentPage('Quiz')}>
                    <span
                      style={
                        isQuiz
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      Quiz
                    </span>
                  </span>
                  <span className="navText1" onClick={() => setCurrentPage('Tv')}>
                    <span
                      style={
                        isLiveTv
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      LiveTV
                    </span>
                  </span>
                  <span className="navText1" onClick={() => setCurrentPage('News')}>
                    <span
                      style={
                        isNews
                          ? {
                              borderBottom: '2px solid',
                              borderImageSlice: 1,
                              borderImageSource: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              color: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
                              WebkitBackgroundClip: 'text',
                              WebkitTextFillColor: 'transparent',
                              backgroundClip: 'text',
                              textFillColor: 'transparent',
                            }
                          : null
                      }
                    >
                      News
                    </span>
                  </span>
                </div>

                <div className={styles.profileWallet}>
                  <div
                    // className={styles.dropItem}
                    style={{ color: '#1fa1d2', cursor: 'pointer' }}
                    onClick={() => setCurrentPage('Wallet')}
                  >
                    <img alt="wallet" src={KWallet} width="25" height="25" style={{ paddingBottom: '5px' }} />
                  </div>
                  <div>
                    <button
                      style={{
                        border: '0',
                        background: 'inherit',
                        marginLeft: '10px',
                      }}
                      onClick={() => setOpenOverlay(true)}
                    >
                      <SearchIcon style={{ color: 'black', background: 'none' }} />
                    </button>
                    <Overlay open={openOverlay} onClose={() => setOpenOverlay(false)}>
                      <Search searchInput={searchInput} setSearchInput={setSearchInput} />
                    </Overlay>
                  </div>
                  <div className="user_profile" style={{ color: 'black' }}>
                    <div style={{ marginLeft: '20px' }}>
                      {auth.user && auth.token ? (
                        <div>
                          <CustomizedMenus
                            img={auth.user.avatar}
                            profile={`/user/${auth.user._id}`}
                            logout={() => {
                              logout();
                              history.push('/');
                              localStorage.removeItem('polBolUser');
                              localStorage.removeItem('authToken');
                            }}
                          />
                        </div>
                      ) : (
                        <Grid
                          item
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <button
                            style={{
                              color: 'black',
                              textDecoration: 'none',
                              fontSize: '14px',
                              cursor: 'pointer',
                              border: '0',
                              borderRadius: '50%',
                              background: 'inherit',
                            }}
                            onClick={() => setOpenLogin(true)}
                          >
                            <img
                              alt="user-avatar"
                              src={UserAvatar}
                              width="25"
                              height="25"
                              style={{ paddingBottom: '5px' }}
                            />
                          </button>
                        </Grid>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {/* <Divider style={{boxShadow: '2px'}} /> */}
      </AppBar>
      <Drawer
        sx={{
          width: isMobile ? '55%' : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: isMobile ? '55%' : drawerWidth,
            overflow: 'auto',
          },
        }}
        variant="temporary"
        onBackdropClick={handleDrawerClose}
        anchor="left"
        open={open}
      >
        {true ? (
          // <p></p>
          <DrawerHeader
            style={{
              backgroundColor: '#f5f5f5',
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'left',
              paddingTop: '15px',
              marginLeft: '-10px',
            }}
          >
            <div className="user_profile" style={{ color: 'black', flex: '1' }}>
              <div style={{ marginLeft: '10px', width: '100%' }}>
                {auth.user && auth.token ? (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    className={styles.antdDrop}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flex: '1',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={() => {
                      handleDrawerClose();
                      history.push(`/user/${auth.user._id}`);
                    }}
                  >
                    <img src={auth.user.avatar} alt="avatar" className="avatar" />{' '}
                    <div className="user-profileInfo">
                      <p
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                          fontSize: '14px',
                        }}
                      >
                        {`${auth.user.firstName} ${auth.user.lastName}`}
                      </p>
                      <p
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                          fontSize: '10px',
                        }}
                      >
                        {auth.user.userName}
                      </p>
                      <p
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                          fontSize: '10px',
                        }}
                      >
                        {auth.user.phone}
                      </p>
                    </div>
                  </a>
                ) : (
                  <Grid
                    item
                    className="user-profileInfo"
                    style={{
                      marginLeft: '0px',
                      marginTop: '5px',
                      cursor: 'pointer',
                      display: 'flex',
                    }}
                  >
                    <div>
                      <PersonIcon
                        className="avatar"
                        style={{
                          backgroundColor: '#ccc',
                          borderRadius: '50%',
                          padding: '4px',
                          width: '40px',
                          height: '40px',
                        }}
                      />
                    </div>
                    <div>
                      <p
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                          fontSize: '14px',
                        }}
                      >
                        Welcome Guest
                      </p>
                      <p
                        role="presentation"
                        style={{
                          color: 'black',
                          textDecoration: 'none',
                          fontSize: '12px',
                        }}
                        onClick={() => {
                          handleDrawerClose();
                          setOpenLogin(true);
                        }}
                      >
                        {' '}
                        Login / Register
                      </p>
                    </div>
                  </Grid>
                )}
              </div>
            </div>
          </DrawerHeader>
        ) : (
          <DrawerHeader style={{ backgroundColor: '#f5f5f5' }}>
            <Typography style={{ margin: 'auto', fontSize: isMobile ? '13px' : '16px' }}> Menu </Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
        )}

        <Divider />
        {isMobile ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              flexDirection: 'column',
              marginTop: '10px',
              marginLeft: '10px',
            }}
          >
            {/* <table> */}
            {['Home', 'Polls', 'Petitions', 'Awards', 'Quiz', 'Live TV', 'News', 'IKC Wallet'].map((text, idx) => (
              <div
                key={text}
                style={{
                  fontSize: isMobile ? '14px' : null,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  paddingLeft: '10px',
                  cursor: 'pointer',
                  marginTop: idx !== 0 && '15px',
                }}
              >
                <div>{LogoReferrence[text]}</div>
                <div>
                  <p
                    role="presentation"
                    style={{ alignItems: 'left', margin: 'unset' }}
                    onClick={() => setCurrentPage(text)}
                  >
                    <span> {text}</span>
                  </p>
                </div>
              </div>
            ))}
            {/* </table> */}

            {auth.user && auth.token ? (
              <div
                style={{
                  fontSize: isMobile ? '14px' : null,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  paddingLeft: '10px',
                  marginTop: '10px',
                  color: 'red',
                }}
              >
                {LogoReferrence.Logout}
                <p
                  role="presentation"
                  style={{
                    alignItems: 'left',
                    marginBottom: '0px !important',
                  }}
                  onClick={() => {
                    logout();
                    handleDrawerClose();
                    history.push('/');
                    localStorage.removeItem('polBolUser');
                    localStorage.removeItem('authToken');
                  }}
                >
                  <span> Logout</span>
                </p>
              </div>
            ) : null}
          </div>
        ) : (
          <List fontSize="10px">
            {['Home', 'Poll', 'Petition', 'Awards', 'Quiz', 'Live TV', 'News', 'IKC Wallet'].map((text) => (
              <div key={text} style={{ fontSize: isMobile ? '12px' : null }}>
                <ListItem
                  button
                  style={{
                    textAlign: 'center',
                    fontSize: isMobile ? '12px' : null,
                  }}
                  onClick={() => setCurrentPage(text)}
                >
                  {text === 'TV' ? (
                    <ListItemText primary="Live TV" primaryTypographyProps={{ fontSize: '10px' }} />
                  ) : (
                    <ListItemText primary={text} style={{ fontSize: isMobile ? '10px !important' : null }} />
                  )}
                </ListItem>
                {/* <Divider style={{ height: '0.5px' }} /> */}
              </div>
            ))}
          </List>
        )}
      </Drawer>
    </Box>
  );
}

const mapStateToProperties = (state) => ({
  auth: state.auth,
  lang: state.lang,
  isMobile: state.media.isMobile,
  isSearchActive: state.search.isSearchActive,
});
const mapDispatchToProperties = (dispatch) => ({
  logout: () => dispatch(logout()),
  updateLanguage: (lang) => dispatch(updateLanguage(lang)),
  setSearchActive: (isSearchActive) => dispatch(setSearchActive(isSearchActive)),
  setMode: (mode) => dispatch(setMode(mode)),
});

export default connect(mapStateToProperties, mapDispatchToProperties)(NavBar);

