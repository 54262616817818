import { ON_CLOSE_SNACKBAR, OPEN_SNACKBAR } from './snackbarTypes';

const initialState = {
  open: false,
  message: { type: 'success', message: '' },
};

export default function snackBarReducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        open: true,
        message: action.payload,
      };
    case ON_CLOSE_SNACKBAR:
      return {
        ...state,
        open: false,
        message: { type: 'success', message: '' },
      };
    default:
      return state;
  }
}
