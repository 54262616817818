import { SET_MEDIA } from './mediaTypes';

const initialState = {
  isMobile: false,
};

const mediaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_MEDIA:
      return { ...state, isMobile: payload };
    default:
      return state;
  }
};
export default mediaReducer;
