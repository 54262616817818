/* eslint-disable react/jsx-props-no-spreading */
import useStyles from './inputCompStyles';

function InputComp(props) {
  const classes = useStyles();
  return (
    <input
      {...props}
      className={classes.inputComp}
      value={props.value}
      onChange={props.onChange}
      type={props.type}
      placeholder={props.placeholder}
    />
  );
}

export default InputComp;
