import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

// Routes
import PrivateRoute from 'PrivateRoute';
import { useMediaQuery } from 'react-responsive';
import { connect } from 'react-redux';

import QuizResult from 'Views/Quiz/QuizResult';
import { updateMobileView } from './redux/media/mediaActions';

const Home = React.lazy(() => import('Views/Home/Home'));
const SearchHome = React.lazy(() => import('Views/Search/SearchHome'));
const Polls = React.lazy(() => import('Views/PollsFinal/Polls'));
const PollDetails = React.lazy(() => import('Views/PollsFinal/Poll/NewPoll'));
const Results = React.lazy(() => import('Views/PollsFinal/Expired/Results'));
const Petitions = React.lazy(() => import('Views/Petitions/Petitions'));

const Awards = React.lazy(() => import('Views/Awards/Awards'));
const AwardsCategory = React.lazy(() => import('Views/Awards/AwardsCategory'));
const FinalAwards = React.lazy(() => import('Views/Awards/FinalAwards/FinalAwards'));
const SubCategories = React.lazy(() => import('Views/Awards/SubCategories/SubCategories'));
const AwardComingSoon = React.lazy(() => import('Views/Awards/AwardComingSoon'));
const News = React.lazy(() => import('Views/NewsFinal/News'));
const IndividualPetition = React.lazy(() => import('Views/Petitions/IndividualPetition'));
const Petition = React.lazy(() => import('Views/Petitions/CreatePetition/Petition'));
const Petition1 = React.lazy(() => import('Views/Petitions/CreatePetition/Petition1'));
const Petition2 = React.lazy(() => import('Views/Petitions/CreatePetition/Petition2'));
const Petition3 = React.lazy(() => import('Views/Petitions/CreatePetition/Petition3'));
const PetitionPreview = React.lazy(() => import('Views/Petitions/CreatePetition/PetitionPreview'));
const EditPetition = React.lazy(() => import('Views/Petitions/EditPetition/index'));
const QuizCat = React.lazy(() => import('Views/Quiz/QuizCat'));
const QuizLevels = React.lazy(() => import('Views/Quiz/QuizLevels'));
const QuizPlay = React.lazy(() => import('Views/Quiz/QuizPlay'));
const Profile = React.lazy(() => import('Views/User/Profile'));
const Wallet = React.lazy(() => import('Views/User/Wallet'));
const Tv = React.lazy(() => import('./Views/TV/Tv'));
const PageNotFound = React.lazy(() => import('./Views/PageNotFound/Pagenotfound'));

function MainView({ setMedia, isSearchActive }) {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  useEffect(() => {
    setMedia(isMobile);
  }, [isMobile]);

  return (
    <Switch>
      <Route exact path="/" component={isSearchActive ? SearchHome : Home} />
      <Route exact path="/polls" component={Polls} />
      <Route exact path="/polls/result/:id" component={Results} />
      <Route exact path="/poll/:slug/:pollId" component={PollDetails} />
      <Route exact path="/petitions" component={Petitions} />
      <Route exact path="/petition" component={Petition} />
      <Route exact path="/petition1" component={Petition1} />
      <Route exact path="/petition2" component={Petition2} />
      <Route exact path="/petition3" component={Petition3} />
      <PrivateRoute exact path="/petition-preview" component={PetitionPreview} />
      <Route exact path="/editPetition/:slug/:petitionId" component={EditPetition} />
      <Route exact path="/petition/:slug/:petitionId" component={IndividualPetition} />
      <Route exact path="/awards" component={Awards} />
      <Route exact path="/awards/categories/subcat/:showId/:categoryId" component={SubCategories} />
      <Route exact path="/awards/categories/:slug/:showId/:voteCount" component={AwardsCategory} />
      <Route exact path="/award/coming_soon" component={AwardComingSoon} />
      <Route exact path="/award/subcat/:showId" component={SubCategories} />
      <Route exact path="/award/now/:awardId" component={FinalAwards} />
      <Route exact path="/awards/categories/subcat/award/:showId/:categoryId/:awardId" component={FinalAwards} />
      <Route exact path="/livetv" component={Tv} />
      <Route exact path="/news" component={News} />
      <Route exact path="/news/:slug/:newsId" component={News} />
      <Route exact path="/quiz" component={QuizCat} />
      <Route exact path="/quiz/result" component={QuizResult} />

      <Route exact path="/quiz/levels/:catId" component={QuizLevels} />
      <Route exact path="/quiz/level/:catId/:quizId/:level" component={QuizPlay} />
      <Route exact path="/user/wallet" component={Wallet} />
      <Route exact path="/user/:userId" component={Profile} />
      <Route exact path="*" component={PageNotFound} />
    </Switch>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setMedia: (isMobile) => dispatch(updateMobileView(isMobile)),
});
const mapStateToProps = (state) => ({
  isMobile: state.isMobile,
  isSearchActive: state.search.isSearchActive,
});
export default connect(mapStateToProps, mapDispatchToProps)(MainView);

