import { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import styles from './QuizResultCircle.module.css';

function ProgressBar({ correct, total }) {
  useEffect(() => {}, []);

  return (
    <div className={styles.container}>
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div className={styles.number}>
            {correct}/{total}
          </div>
        </div>
        <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
          <defs>
            <linearGradient id="GradientColor">
              <stop offset="0%" stopColor="#88259f" />
              <stop offset="50%" stopColor="#bd3881" />
              <stop offset="100%" stopColor="#a52f8e" />
            </linearGradient>
          </defs>
          <StyledCircle cx="80" cy="80" r="70" dashOffset={439 - 439 * (correct / total)} />
        </svg>
      </div>
    </div>
  );
}

const animateGradient = (dashOffset) => keyframes`
100%{
  stroke-dashoffset: ${dashOffset};
}
`;
const StyledCircle = styled.circle`
  fill: none;
  stroke: url(#GradientColor);
  stroke-width: 20px;
  stroke-dasharray: 439;
  stroke-dashoffset: 439;
  stroke-linecap: round;
  animation: ${(props) => animateGradient(props.dashOffset)} 2s linear forwards;
`;

export default ProgressBar;

ProgressBar.defaultProps = {
  correct: 10,
  total: 25,
};
