/* eslint-disable prefer-template */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getSlug } from 'helpers/index';
// eslint-disable-next-line import/no-relative-packages
import { Box, Typography } from '../../../node_modules/@material-ui/core/index';
// APIS
import { getSearchResult } from '../../api/search';

// IMAGES
import searchIcon from '../../assets/search-icon.svg';
import searchCancelIcon from '../../assets/search-cancel-icon.svg';

// STYLES
// import useStyles from './searchStyles'
import classes from './index.module.css';

function Search({ isMobile, getSearchResult, isPoll, style, mode = '', templateData, searchInput, setSearchInput }) {
  const location = useLocation();
  const Home = location.pathname.split('/')[1] === '';
  const Poll = location.pathname.split('/')[1] === 'polls';
  const Petition = location.pathname.split('/')[1] === 'petitions';
  const News = location.pathname.split('/')[1] === 'news';
  const height = window.innerHeight;

  const [polls, setPolls] = useState([]);
  const [petitions, setPetitions] = useState([]);
  const [news, setNews] = useState([]);

  // console.log('from search', style);
  // console.log(Home, Poll, Petition, News);

  const initialSearchResults = Home
    ? [
        {
          title: 'polls',
          buttonText: 'Vote',
          list: mode === '' ? templateData[0] : [],
        },
        {
          title: 'petitions',
          buttonText: 'Sign',
          list: mode === '' ? templateData[1] : [],
        },
        {
          title: 'news',
          buttonText: 'View',
          list: mode === '' ? templateData[2] : [],
        },
      ]
    : Poll
    ? [
        {
          title: 'polls',
          buttonText: 'Vote',
          list: mode === '' ? templateData[0] : [],
        },
      ]
    : Petition
    ? [
        {
          title: 'petitions',
          buttonText: 'Sign',
          list: mode === '' ? templateData[1] : [],
        },
      ]
    : [
        {
          title: 'news',
          buttonText: 'View',
          list: mode === '' ? templateData[2] : [],
        },
      ];

  const [searchResults, setSearchResults] = useState(initialSearchResults);
  const searchHandler = async () => {
    if (searchInput) {
      try {
        const data = await getSearchResult(searchInput, mode);
        if (Home) {
          const tempPolls = {
            ...searchResults[0],
            list: data?.payload?.polls?.slice(0, 3) || [],
          };
          const tempPetitions = {
            ...searchResults[1],
            list: data?.payload?.petitions?.slice(0, 3) || [],
          };
          const tempNews = {
            ...searchResults[2],
            list: data?.payload?.news?.slice(0, 3) || [],
          };
          if (Poll) setSearchResults([tempPolls]);
          else if (Petition) setSearchResults([tempPetitions]);
          else if (News) setSearchResults([tempNews]);
          else if (Home) setSearchResults([tempPolls, tempPetitions, tempNews]);
          setPolls(data.payload.polls);
          setPetitions(data.payload.petitions);
          setNews(data.payload.news);
        } else if (Poll) {
          // console.log(searchResults);
          const tempPolls = {
            ...searchResults[0],
            list: data?.payload?.polls?.slice(0, 3) || [],
          };
          const tempPetitions = {
            ...searchResults[1],
            list: data?.payload?.petitions?.slice(0, 3) || [],
          };
          const tempNews = {
            ...searchResults[2],
            list: data?.payload?.news?.slice(0, 3) || [],
          };
          if (Poll) setSearchResults([tempPolls]);
          else if (Petition) setSearchResults([tempPetitions]);
          else if (News) setSearchResults([tempNews]);
          else if (Home) setSearchResults([tempPolls, tempPetitions, tempNews]);
          setPolls(data.payload.polls);
          setPetitions(data.payload.petitions);
          setNews(data.payload.news);
        } else if (Petition) {
          const tempPolls = {
            ...searchResults[0],
            list: data?.payload?.polls?.slice(0, 3) || [],
          };
          const tempPetitions = {
            ...searchResults[0],
            list: data?.payload?.petitions?.slice(0, 3) || [],
          };
          const tempNews = {
            ...searchResults[2],
            list: data?.payload?.news?.slice(0, 3) || [],
          };
          if (Poll) setSearchResults([tempPolls]);
          else if (Petition) setSearchResults([tempPetitions]);
          else if (News) setSearchResults([tempNews]);
          else if (Home) setSearchResults([tempPolls, tempPetitions, tempNews]);
          setPolls(data.payload.polls);
          setPetitions(data.payload.petitions);
          setNews(data.payload.news);
        } else {
          const tempPolls = {
            ...searchResults[0],
            list: data?.payload?.polls?.slice(0, 3) || [],
          };
          const tempPetitions = {
            ...searchResults[1],
            list: data?.payload?.petitions?.slice(0, 3) || [],
          };
          const tempNews = {
            ...searchResults[0],
            list: data?.payload?.news?.slice(0, 3) || [],
          };
          if (Poll) setSearchResults([tempPolls]);
          else if (Petition) setSearchResults([tempPetitions]);
          else if (News) setSearchResults([tempNews]);
          else if (Home) setSearchResults([tempPolls, tempPetitions, tempNews]);
          setPolls(data.payload.polls);
          setPetitions(data.payload.petitions);
          setNews(data.payload.news);
        }
      } catch (error) {
        // console.log(error);
      }
    } else {
      setSearchResults(initialSearchResults);
    }
  };

  const history = useHistory();
  const viewDoc = (item, title) => {
    let searchTerm = title;
    if (title[0] !== 'n') searchTerm = title.slice(0, title.length - 1);
    const toSlug = item.question ? item.question : item.title;
    history.push(`/${searchTerm}/${getSlug(toSlug)}/${item._id}`);
  };

  useEffect(() => {
    const searchDelay = setTimeout(() => searchHandler(searchInput, mode), 500);
    return () => clearTimeout(searchDelay);
  }, [searchInput, mode]);

  const searchIconStyle = searchInput ? { transform: 'translateY(-100%)' } : { transform: 'translateY(-75%)' };

  const clearHandler = () => setSearchInput('');
  const TitleRef = useRef();
  function highlight(text) {
    // if(TitleRef.current==null) return;
    const inputText = document.getElementById('TitleText');
    let innerHTML = inputText?.innerHTML;
    const index = innerHTML?.indexOf(text);
    if (index >= 0) {
      innerHTML =
        innerHTML.substring(0, index) +
        "<span style='background-color:'yellow''>" +
        innerHTML.substring(index, index + text.length) +
        '</span>' +
        innerHTML.substring(index + text.length);
      inputText.innerHTML = innerHTML;
    }
  }
  const resultsLengthCalculator = () => {
    const len0 = polls?.length || 0;
    const len1 = petitions?.length || 0;
    const len2 = news?.length || 0;

    return Home ? len0 + len1 + len2 : Poll ? len0 : Petition ? len1 : len2;
  };
  const moreHandler = (idx) => {
    if (idx === 0) {
      setSearchResults([
        {
          ...searchResults[0],
          list: polls.slice(0, searchResults[0].list.length + 3),
        },
        { ...searchResults[1] },
        { ...searchResults[2] },
      ]);
    } else if (idx === 1) {
      setSearchResults([
        { ...searchResults[0] },
        {
          ...searchResults[1],
          list: petitions.slice(0, searchResults[1].list.length + 3),
        },
        { ...searchResults[2] },
      ]);
    } else {
      setSearchResults([
        { ...searchResults[0] },
        { ...searchResults[1] },
        {
          ...searchResults[2],
          list: news.slice(0, searchResults[2].list.length + 3),
        },
      ]);
    }
  };
  function getHighlightedText(text) {
    const highlight = searchInput;
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { background: 'yellow' } : {}}>
            {part}
          </span>
        ))}{' '}
      </span>
    );
  }

  return (
    <Box
      className={!isMobile ? classes.container : classes.mobileContainer}
      style={{
        maxHeight: height - 10,
        paddingBottom: isPoll && '0px',
        marginTop: style && '0px',
      }}
    >
      <Box className={classes.inputWrapper}>
        <input
          type="text"
          placeholder={`Search for${
            mode === 'polls'
              ? ' polls'
              : mode === 'petitions'
              ? ' petitions'
              : mode === 'news'
              ? ' news'
              : ' polls, petitions and news'
          }`}
          className={classes.inputField}
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
            highlight(e.target.value);
          }}
        />
        <img
          role="presentation"
          className={classes.inputFieldImage}
          onClick={searchInput ? clearHandler : searchHandler}
          src={searchInput ? searchCancelIcon : searchIcon}
          style={searchIconStyle}
          alt="Search"
        />
      </Box>
      {resultsLengthCalculator() && searchInput ? (
        <Typography
          className={classes.noOfResults}
        >{`Showing ${resultsLengthCalculator()} results for ${searchInput}`}</Typography>
      ) : (
        <></>
      )}
      {searchResults.map((dataType, i) =>
        dataType?.list?.length ? (
          <Box className={classes.cardWrapper}>
            <Typography variant="body1" className={classes.cardTitle}>
              {dataType.title}
            </Typography>
            <Box className={classes.listDeck}>
              {dataType.list.map((item) => (
                <Box
                  className={classes.listItem}
                  onClick={() => {
                    viewDoc(item, dataType.title);
                  }}
                >
                  <img
                    src={typeof item.image === 'string' || item.image instanceof String ? item.image : item.images[0]}
                    alt={searchInput}
                    className={classes.listItemImage}
                  />
                  <Box className={classes.itemTextWrapper}>
                    <Typography
                      variant="caption"
                      ref={TitleRef}
                      id="TitleText"
                      style={{ fontWeight: !isMobile && '600' }}
                      className={classes.itemTitle}
                    >
                      {' '}
                      {item.title
                        ? getHighlightedText(item.title)
                        : item.question
                        ? getHighlightedText(item.question)
                        : item.headline
                        ? getHighlightedText(item.headline)
                        : ''}
                    </Typography>
                    {item.content ? (
                      <Typography
                        variant="overline"
                        className={classes.itemSubtitle}
                        dangerouslySetInnerHTML={{
                          __html:
                            item && item.content?.length > 100
                              ? `${item.content.replace('<br>', '').replace('<p></p>', '').slice(0, 100)}...`
                              : item.content,
                        }}
                      />
                    ) : (
                      <Typography variant="overline" className={classes.itemSubtitle}>
                        {item.description ? item.description.substring(0, 77) + '...' : ''}
                      </Typography>
                    )}
                  </Box>
                  <button
                    className={classes.itemCta}
                    onClick={() => {
                      viewDoc(item, dataType.title);
                    }}
                  >
                    {dataType.buttonText}
                  </button>
                </Box>
              ))}
            </Box>
            {i === 0 ? (
              searchResults[i].list?.length < polls?.length ? (
                <Typography onClick={() => moreHandler(i)} variant="subtitle1" className={classes.more}>
                  More
                </Typography>
              ) : (
                <></>
              )
            ) : i === 1 ? (
              searchResults[i].list?.length < petitions?.length ? (
                <Typography onClick={() => moreHandler(i)} variant="subtitle1" className={classes.more}>
                  More
                </Typography>
              ) : (
                <></>
              )
            ) : searchResults[i].list?.length < news?.length ? (
              <Typography onClick={() => moreHandler(i)} variant="subtitle1" className={classes.more}>
                More
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        ) : (
          <></>
        ),
      )}
    </Box>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isMobile: state.media.isMobile,
  // mode: state.search.mode,
  templateData: state.search.templateData,
});

const mapDispatchToProps = {
  getSearchResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);

