/* eslint-disable react/jsx-props-no-spreading */
import { useMediaQuery } from 'react-responsive';
import { Box, Typography } from '@material-ui/core/index';

import useStyles from './headingCompStyles';

function HeadingComp(props) {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const classes = useStyles();
  const { heading } = props;
  return (
    <Box className={classes.headingWrapper}>
      <Typography
        variant="h2"
        className={classes.headingText}
        {...props}
        style={{
          fontSize: isMobile ? (props.smaller === 'true' ? 20 : 22) : props.isLive ? 26 : 29,
          // width: isMobile && '70%',
          fontWeight: props.isThin ? '500' : '700',
          lineHeight: props.isLogin ? '148%' : 'unset',
        }}
      >
        {heading}
      </Typography>
    </Box>
  );
}

export default HeadingComp;
