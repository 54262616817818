import { connect } from 'react-redux';

import { Box, Typography } from '@material-ui/core/index';
import useStyles from './footerStyles';

import brandLogo from '../../assets/footer-brand-logo.svg';
import instagramLogo from '../../assets/footer-instagram-logo.svg';
import facebookLogo from '../../assets/footer-facebook-logo.svg';
import twitterLogo from '../../assets/footer-twitter-logo.svg';

function FooterFinal({ isMobile }) {
  const classes = useStyles();
  const links = [
    {
      alt: 'instagram',
      image: instagramLogo,
      url: 'https://www.instagram.com/polbol.media/',
    },
    {
      alt: 'facebook',
      image: facebookLogo,
      url: 'https://www.facebook.com/polbol.media',
    },
    {
      alt: 'twitter',
      image: twitterLogo,
      url: 'https://twitter.com/polbolmedia',
    },
  ];

  return (
    <div component="footer" className={classes.footer} style={{ padding: !isMobile && '22px 40px 27px 40px' }}>
      <Box className={classes.linksWrapper}>
        <Box component="img" src={brandLogo} alt="Brand" className={classes.brandLogo} />

        <Box className={classes.socialLinksWrapper}>
          {links.map((item) => (
            <a href={item.url} key={item.url} className={classes.socialLink} target="_blank" rel="noreferrer">
              <Box component="img" src={item.image} alt={item.alt} />
            </a>
          ))}
        </Box>
      </Box>

      <Box className={classes.textWrapper}>
        <Box className={classes.citationWrapper}>
          <Typography variant="caption" className={classes.copyright} style={{ fontSize: !isMobile && 13 }}>
            Copyright © 2022 All rights reserved |
          </Typography>
          <Typography
            variant="caption"
            className={classes.creatorLink}
            style={{ fontWeight: 600, fontSize: !isMobile && 13, marginTop: 5 }}
          >
            This Website is made by
            <a href="https://yboxmedia.com/" target="_blank" rel="noreferrer">
              {' '}
              YBox Technologies
            </a>
          </Typography>
        </Box>
        <Box className={classes.otherLinksWrapper}>
          <Typography variant="caption" className={classes.otherLink} style={{ fontSize: !isMobile && 13 }}>
            <a href="https://apps.apple.com/pa/app/polbol/id1476395002?l=en/" target="_blank" rel="noreferrer">
              Download App
            </a>
          </Typography>
          <Typography variant="caption" className={classes.otherLink} style={{ fontSize: !isMobile && 13 }}>
            <a href="https://polbol-media.s3.ap-south-1.amazonaws.com/ToS.pdf/" target="_blank" rel="noreferrer">
              Terms and Conditions
            </a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
}
const mapStateToProperties = (state) => ({
  isMobile: state.media.isMobile,
});

export default connect(mapStateToProperties)(FooterFinal);

