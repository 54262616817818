import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  btn: {
    display: 'flex',
    border: 'none',
    position: 'relative',
    justifyContent: 'space-around',
    padding: '0.75rem 2.5rem',
    fontSize: '16px',
    background: theme.palette.primary.mainGradient,
    color: theme.palette.white.main,
    width: '37%',
    borderRadius: '8px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500 !important',
    maxWidth: 'unset !important',

    // fontSize: '12px',
    lineHeight: '115.5%',
    // padding: '9px 40px',
    textTransform: 'capitalize !important',
    outline: 'none',
    '@media (max-width:450px)': {
      // eslint-disable-line no-useless-computed-key
      fontSize: '14px',
    },
    // '& :hover': {
    //   background: theme.palette.white.main,
    //   border: '1px solid',

    //   borderImageSource:
    //     'linear-gradient( 132.33deg, #d24074 -0.67%, #6518b4 102.54%)',
    //   borderImageSlice: '1',

    //   // '& span': {
    //   //   background:
    //   //     'linear-gradient(132.33deg, #d24074 -0.67%, #6518b4 102.54%)',
    //   //   WebkitBackgroundClip: 'text',
    //   //   WebkitTextFillColor: 'transparent',
    //   //   backgroundClip: 'text',
    //   // },
    // },
  },

  btnSecondary: {
    display: 'flex',
    position: 'relative',
    border: 'none',
    justifyContent: 'space-around',
    padding: '0.75rem 2rem',
    fontSize: '22px',
    width: 'fit-content',
    background: '#F0F0F0',
    borderRadius: '3px',
    // fontSize: '12px',
    lineHeight: '115.5%',
    // padding: '9px 40px',
    textTransform: 'capitalize !important',
    outline: 'none',

    '& span': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: '600 !important',
      fontSize: '12px',
      lineHeight: '115.5%',
      background: 'linear-gradient(132.33deg, #D24074 -0.67%, #6518B4 102.54%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      maxWidth: 'unset !important',
      textFillColor: 'transparent',
    },
  },
}));
