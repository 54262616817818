import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  inputComp: {
    border: 'double 1px transparent',
    borderRadius: '4px',
    backgroundImage: 'linear-gradient(white, white), linear-gradient(to right, #d24074, #6518b4)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'padding-box, border-box',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '15px',
    marginBottom: '10px',
    maxHeight: '45px',
    width: '100%',

    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '10px',
    lineHeight: '127%',
    color: '#212121',

    '& ::placeholder': {
      color: '#878787',
    },

    '& ::-webkit-outer-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },
    '&::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: '0',
    },

    '& [type=number]': {
      MozAppearance: 'textfield',
    },
  },
}));
