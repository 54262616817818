import { SET_LANGUAGE } from './languageTypes';

const language = localStorage.getItem('polbolLanguage');

const initialState = {
  language: language || 'English',
};

const languageReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LANGUAGE:
      localStorage.setItem('polbolLanguage', payload);
      return { ...state, language: payload };

    default:
      return state;
  }
};
export default languageReducer;
