/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line react/jsx-props-no-spreading
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = useSelector(({ auth }) => auth.isAuthenticated);

  return (
    <Route
      {...rest}
      render={(properties) =>
        isAuthenticated ? (
          <Component {...properties} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: properties.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
