import { SET_MODE, TOGGLE_SEARCH_ACTIVE, SET_TEMPLATE_DATA } from './searchTypes';

const initialState = {
  isSearchActive: false,
  mode: '',
  templateData: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODE:
      return { ...state, mode: action.payload };

    case TOGGLE_SEARCH_ACTIVE:
      return { ...state, isSearchActive: action.payload };

    case SET_TEMPLATE_DATA:
      return { ...state, templateData: action.payload };
    default:
      return state;
  }
};
export default searchReducer;
