import { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { connect, useDispatch } from 'react-redux';

import { Modal, Box } from '@mui/material';
import { RESET_OPT_VALID_STATUS } from 'redux/auth/authTypes';

import CloseIcon from '@mui/icons-material/Close';
import SnackBar from 'Components/Snackbar/SnackBar';
import InputComp from '../InputComp/InputComp';
import ButtonComp from '../ButtonComp/ButtonComp';
import HeadingComp from '../HeadingComp/HeadingComp';

import { GetUserOTP, SendUserOTP } from '../../api/auth';

import shieldIcon from '../../assets/shield-icon.svg';

import ReferralCodes from './ReferralCodes';

import styles from './Modal.module.css';

function LoginModal({ title, auth, GetUserOTP, SendUserOTP, show, onHide, isMobile }) {
  const { isAuthenticated, isOTPsend, user, isOTPValid: valid } = auth;

  const [otp, setOtp] = useState(null);

  const dispatch = useDispatch();

  const [number, setNumber] = useState('');

  const [isFetching, setIsFetching] = useState(false);

  const [phoneData, setPhoneData] = useState('');

  const [submitStatus, setSubmitStatus] = useState(false);

  const [referralCode, setReferralCode] = useState('');

  const [refCodeError, setRefCodeErr] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const [snack, setSnack] = useState({ type: 'success', message: 'OTP Sent' });

  useEffect(() => {
    if (isOTPsend) {
      setSubmitStatus(true);
      setPhoneData(user);
    } else {
      setSubmitStatus(false);
    }
  }, [isOTPsend, user]);

  useEffect(() => {
    if (isAuthenticated) {
      setNumber('');
      setOtp(null);
      setSubmitStatus(false);
      setPhoneData('');
    }
  }, [isAuthenticated]);

  const handlePhoneNumChange = (e) => {
    const num = e.target.value.slice(0, 10);
    if (!Number.isNaN(e.target.value)) setNumber(num);
  };
  const handleRefCodeChange = (e) => {
    setReferralCode(e.target.value);
  };

  const getOtp = async (number_) => {
    try {
      if (referralCode !== '' && ReferralCodes.findIndex((code) => code === referralCode.trim()) === -1) {
        setOpenSnack(true);
        setSnack({ type: 'error', message: 'Invalid Referral code.' });
        setRefCodeErr(true);
      } else {
        setIsFetching(true);
        setRefCodeErr(false);
        setOpenSnack(true);
        setSnack({ type: 'success', message: 'OTP sent !' });
        setOpenSnack(true);
        await GetUserOTP(number_);
        setIsFetching(false);
      }
    } catch (error) {
      setOpenSnack(true);

      setSnack({
        type: 'error',
        message: 'Error in sending OTP. Please Try again',
      });
      setIsFetching(false);
    }
  };

  const handleOtpChange = async (otp) => {
    try {
      setOtp(otp);
      if (otp && otp.length !== 4 && !valid) {
        dispatch({
          type: RESET_OPT_VALID_STATUS,
        });
      }
      if (otp.length === 4) {
        const userId = user._id;
        if (userId) {
          const cb = () => {
            setSnack({ type: 'success', message: 'Login Successful !  ' });
            setOpenSnack(true);
            onHide();
          };
          await SendUserOTP(userId, otp, cb);
        }
      }
    } catch (error) {
      setSnack({ type: 'error', message: 'Something went wrong! Try again.' });
      setOpenSnack(true);
    }
  };

  const handleCancel = () => {
    onHide();
    setSubmitStatus(false);
    setNumber(null);
    setOtp(null);
    setPhoneData('');
  };

  return (
    <div>
      <SnackBar
        open={openSnack}
        setOpen={setOpenSnack}
        snack={snack}
        style={{ height: '100%' }}
        onClose={() => setOpenSnack(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <Modal open={show} onHide={onHide} onClose={handleCancel} size="md" aria-labelledby="modal-Login" centered>
        <Box
          sx={{
            maxWidth: '95vw',
            top: '50% !important',
            left: '50% !important',
            borderRadius: '6px !important',
            position: 'absolute !important',
            padding: '30px 10px !important',
            background: '#FFFFFF !important',
            boxSizing: 'border-box !important',
            border: '1px solid #FFF7F7 !important',
            width: isMobile ? '95vw' : '500px !important',
            transform: 'translateX(-50%) translateY(-50%) !important',
            boxShadow: '0px 4px 8px -4px rgba(22, 34, 51, 0.08), 0px 16px 24px rgba(22, 34, 51, 0.08) !important',
          }}
        >
          {isMobile && (
            <span
              style={{
                position: 'absolute',
                top: 20,
                right: 20,
              }}
              onClick={() => onHide()}
            >
              <CloseIcon className={styles.LinearText} />
            </span>
          )}
          <HeadingComp
            style={{
              fontSize: '16px',
              lineHeight: '150%',
              fontWeight: '500 !important',
              marginTop: '25px',
            }}
            isLogin
            isLive
            isThin
            heading={title}
          />
          <div className={styles.center}>
            <InputComp
              style={{
                fontSize: 13,
              }}
              value={number}
              onChange={handlePhoneNumChange}
              maxLength="10"
              type="number"
              placeholder="Phone Number"
            />
            <InputComp
              style={{
                fontSize: 13,
              }}
              type="text"
              onChange={handleRefCodeChange}
              placeholder="Referral code(if any)"
              value={referralCode}
            />

            <span className={styles.errormssg} style={{ padding: '5px' }}>
              {refCodeError ? 'Invalid Referral code' : ''}
            </span>
            {!isFetching && (
              <ButtonComp
                text={submitStatus ? 'Resend OTP' : 'Send OTP'}
                onClick={() => {
                  // console.log(number.length)
                  if (number.length !== 10) {
                    return;
                  }
                  getOtp(number);
                }}
                style={{
                  maxWidth: '170px',
                  padding: '10px 9px',
                  borderRadius: 4,
                }}
              />
            )}
          </div>
          {Object.keys(phoneData).length > 0 && (
            <div className={styles.OtpBox}>
              <p className={styles.pleaseEnterOTP}>Please enter OTP</p>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={4}
                containerStyle={styles.container}
                inputStyle={valid ? styles.valid : styles.invalid}
                isInputNum
              />
              {!valid && otp && otp.length === 4 && <p className={styles.invalidOTPText}>Invalid OTP</p>}
            </div>
          )}
          <div className={styles.terms}>
            <img src={shieldIcon} alt="Privacy" />
            <p className={styles.policyQuote}>
              We are not storing any private information. <br /> By continuing you agree to our{' '}
              <span className={styles.cond}>
                <a target="_blank" href="https://polbol-media.s3.ap-south-1.amazonaws.com/ToS.pdf" rel="noreferrer">
                  Terms and Conditions
                </a>
              </span>
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state, props) => ({
  ...props,
  auth: state.auth,
});

const mapDispatchToProps = {
  GetUserOTP,
  SendUserOTP,
};

LoginModal.defaultProps = {
  title: 'Login',
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

