import { SET_MODE, TOGGLE_SEARCH_ACTIVE, SET_TEMPLATE_DATA } from './searchTypes';

export const setSearchActive = (isSearchActive = true) => ({
  type: TOGGLE_SEARCH_ACTIVE,
  payload: isSearchActive,
});

export const setMode = (mode = null) => ({
  type: SET_MODE,
  payload: mode,
});

export const setTemplateData = (templateData) => ({
  type: SET_TEMPLATE_DATA,
  payload: templateData,
});
