/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect } from 'react';
// import './style.css'
import Slider from 'react-slick';
import { Row } from 'react-bootstrap';
import { NavLink, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import '../PollsFinal/Polls/pollsCard.css';
// APIS
import { getPolls } from 'api/polls';
import { getAllCategories } from 'api/quiz';
import { useMediaQuery } from 'react-responsive';
import icon1 from '../../assets/category-icon-1.svg';
import icon2 from '../../assets/category-icon-2.svg';
import icon3 from '../../assets/category-icon-3.svg';
import icon4 from '../../assets/category-icon-4.svg';
import icon5 from '../../assets/category-icon-5.svg';
import icon6 from '../../assets/category-icon-6.svg';
import icon7 from '../../assets/category-icon-7.svg';
import icon8 from '../../assets/category-icon-8.svg';

import styles from './Quiz.module.css';

const categoryIcons = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8];

function DiscoverQuizs() {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const history = useHistory();
  const [discoverQuiz, setDiscoverQuiz] = useState([]);
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getAllCategories();
        const mainData = await data();
        setDiscoverQuiz(mainData.payload);
      } catch (error) {
        // console.log('Error fetching categories', error);
      }
    };
    fetchCategories();
  }, []);

  const settings = {
    infinite: discoverQuiz?.length > 3,
    slidesToShow: 5,
    slidesToScroll: 3,
    // speed: 2000,
    variableWidth: true,
    nextArrow: isMobile && <></>,
    prevArrow: isMobile && <></>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          background: !isMobile && '#FFF7F7',
          padding: isMobile ? '0px 40px' : 80,
          width: isMobile ? '100vw' : '1200px',
        }}
      >
        <div
          style={{
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'space-between',
            background: !isMobile && '#FFF7F7',
          }}
        >
          <h4 className={styles.quizHeading} style={{ fontSize: isMobile && '18px' }}>
            Discover more
          </h4>
          <NavLink to="/quiz">
            <button type="button" className={styles.quizViewBtn} style={{ fontSize: isMobile && '15px' }}>
              View All
            </button>
          </NavLink>
        </div>
        <Row>
          <Slider {...settings} style={{ height: isMobile && '150px !important' }}>
            {discoverQuiz?.map((category, index) => (
              <div
                role="presentation"
                onClick={() => {
                  // console.log(category);
                  history.push(`/quiz/levels/${category._id}`);
                }}
                //   onClick={() => handleClick(category._id)}
                className={isMobile ? styles.mainCategory : styles.mainCategoryWeb}
                style={
                  {
                    // width: isMobile && '101px !important',
                    // height: isMobile && '101px !important',
                    // marginLeft: isMobile && '16px !important',
                  }
                }
              >
                <img
                  alt="category"
                  src={categoryIcons[index] || category.icon}
                  className={isMobile ? styles.image : styles.imageWeb}
                />
                <div className={isMobile ? styles.categoryName : styles.categoryNameWeb}>{category.name}</div>
              </div>
            ))}
          </Slider>
        </Row>
      </div>
    </div>
  );
}
const mapDispatchToProperties = {
  getPolls,
};
const mapStateToProperties = (state, props) => ({
  props: props.data,
  lang: state.lang,
  auth: state.auth,
});
export default connect(mapStateToProperties, mapDispatchToProperties)(DiscoverQuizs);
