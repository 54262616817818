import axios from './axios';

export const getHighlightedPolls = () => {
  const URL = '/polls/highlighted?language=english';
  return () =>
    axios
      .get(URL)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// Get Polls with params
export const getPolls = (params = {}, secure = false) => {
  const URL = secure ? '/polls' : '/common/polls';

  if (params.mode === 'all') delete params.mode;

  return () =>
    axios
      .get(URL, {
        params,
      })
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// Get Polls Results
export const getPollResults = (pollId) => () =>
  axios
    .get(`/poll/results/guest?id=${pollId}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error && error.response && error.response.data));

// GET SINGLE POLL
export const getSinglePoll = (pollId, secure = false) => {
  const URL = secure ? `/poll/${pollId}` : `/common/poll/${pollId}`;

  return () =>
    axios
      .get(URL)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// GET ALL COMMENTS FOR A POLL
export const getAllComments = (pollId, secure = false) => {
  const URL = secure ? `/poll/comments?poll=${pollId}` : `/common/polls/comments?poll=${pollId}`;

  return () =>
    axios
      .get(URL)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};
export const getMoreComments = (pollId, lastTime, secure = false) => {
  const URL = secure
    ? `/poll/comments?poll=${pollId}&lastTime=${lastTime}`
    : `common/polls/comments?poll=${pollId}&lastTime=${lastTime}`;
  return () =>
    axios
      .get(URL)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error && error.response && error.response.data));
};

// POST A ANSWER
export const postAnswer = (data) => () =>
  axios
    .post('/poll/add-answer', data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error && error.response && error.response.data));

// REGION DATA
export const getRegions = () => () =>
  axios
    .get('/region')
    .then((response) => response.data)
    .catch((error) => Promise.reject(error && error.response && error.response.data));
